import ListPlayermonItemsNFT from '../data/listPlayermonItemsNFT.json'

export const generateEmbededCode = (
  url: string,
  width: number,
  height: number
): string => {
  return `<iframe src="${url}" frameborder=0 ALLOWTRANSPARENCY="true" style="width:${width}px;height:${height}px"></iframe>`
}

export const classMappingByNumber = (label: number): string | null => {
  switch (label) {
    case 1:
    case 5:
    case 6:
    case 23:
    case 30:
    case 35:
      return 'comet'

    case 2:
    case 7:
    case 9:
    case 12:
    case 20:
    case 36:
      return 'aqua'

    case 3:
    case 8:
    case 13:
    case 14:
    case 24:
    case 33:
      return 'nature'

    case 4:
    case 10:
    case 11:
    case 18:
    case 25:
    case 32:
      return 'inferno'

    case 15:
    case 17:
    case 27:
    case 29:
    case 34:
    case 21:
      return 'mech'

    case 16:
    case 19:
    case 22:
    case 26:
    case 28:
    case 31:
      return 'mythos'

    default:
      return null
  }
}

export const getImage = (itemId: number) => {
  const item = ListPlayermonItemsNFT.find(
    item => item.ItemId === Number(itemId)
  )
  return item?.image
}

export const getNameItem = (itemId: number) => {
  const item = ListPlayermonItemsNFT.find(
    item => item.ItemId === Number(itemId)
  )

  return item?.name
}

export const getDescription = (itemId: number) => {
  const item = ListPlayermonItemsNFT.find(item => item.ItemId === itemId)
  return item?.description
}
